import React, { Component } from 'react'
import { Link } from 'gatsby'
import Video from 'react-player'

// Components Globals
import Header from 'components/header'
import Navigation from 'components/navigation-solutions'
import Footer from 'components/footer/main'
import Demo from 'components/s-demo/main'
import SEO from 'components/seo'

// Sass Global
import 'sass/main.scss'

import { BannerSolucao, TituloPrimario, TextParagrafo, SectionVideo, SectionPraticidade, SectionIntegracoes } from '../../Style/solucoes'

import { Container, TitleBanner } from '../../Style/global'

import IlustraControleEstoque from 'images/ilustracao-controle-estoque.svg'
import IconeCoteFacil from 'images/icone-cote-facil.svg'
import IconeAbcFarma from 'images/icone-abc-farma.svg'
import IconePedidoEletronico from 'images/icone-pedido-eletronico.svg'
import IlustracaoPedidoCompra from 'images/ilustracao-pedido-compra.svg'
import IlustracaoPrecificacao from 'images/ilutracao-precificacao.svg'
import IlustracaoCurvaABC from 'images/ilustracao-curva-abc.svg'
import IlustracaoGestaoCategoria from 'images/ilutracao-gestao-categoria.svg'
import IconePedidoCompra from 'images/icone-pedido-compra.svg'
import IconeGestaoCategoria from 'images/icone-gestao-categoria.svg'

class PageControleEstoque extends Component {
  render() {
    return (
      <div className="main">
        <SEO title="Controle de Estoque" />

        <Header />

        <Navigation from={this.props.location.pathname} />

        <BannerSolucao>
          <Container className="flex">
            <div className="texto">
              <TitleBanner>controle de estoque</TitleBanner>
              <h1>Não perca mais vendas por ruptura de estoque</h1>
              <p>
                Utilize as principais ferramentas para controlar
                <br /> o estoque da sua farmácia.
              </p>
              <Link to="/sobre/contato">Solicite uma demonstração</Link>
            </div>
            <img src={IlustraControleEstoque} alt="Tela sistema Inovafarma gerenciar produtos" className="ilustra" />
          </Container>
        </BannerSolucao>

        <SectionPraticidade>
          <Container>
            <TituloPrimario>Tenha praticidade no seu controle de estoque</TituloPrimario>
            <TextParagrafo>Com o INOVAFARMA fica mais fácil manter as mercadorias do estoque sob controle</TextParagrafo>
            <div className="all-cards">
              <div className="card">
                <div className="img">
                  <img src={IconeCoteFacil} alt="Icone caixa" />
                </div>
                <h4>Cotefácil</h4>
                <p>Economize tempo e dinheiro encontrando a melhor opção de compra para sua farmácia.</p>
              </div>
              <div className="card">
                <div className="img">
                  <img src={IconeAbcFarma} alt="Icone preços" />
                </div>
                <h4>ABC Farma</h4>
                <p>Acompanhe os preços dos produtos da farmácia conforme praticado na sua região</p>
              </div>
              <div className="card">
                <div className="img">
                  <img src={IconePedidoEletronico} alt="Icone gráfico" />
                </div>
                <h4>Pedido eletrônico</h4>
                <p>Simplifique os pedidos de compra da sua farmácia com os principais distribuidores do Brasil.</p>
              </div>
            </div>
          </Container>
        </SectionPraticidade>

        <SectionIntegracoes>
          <Container>
            <TituloPrimario>Integrações que potencializam sua farmácia</TituloPrimario>
            <TextParagrafo>
              O INOVAFARMA oferece integração com ferramentas que vão ajudar sua <br />
              farmácia ser ainda mais produtiva.
            </TextParagrafo>

            <div className="all-integracoes">
              <div className="item">
                <img src={IlustracaoPedidoCompra} alt="Tela sistema Inovafarma lançar pedidos" className="ilustra" />
                <div className="texto">
                  <img src={IconePedidoCompra} alt="Icone compras" className="icone" />
                  <TituloPrimario>Pedido de compra</TituloPrimario>
                  <p>Ganhe tempo nas operações de compra da sua farmácia com as ferramentas de projeção da demanda e estoque regulador.</p>
                </div>
              </div>
              <div className="item">
                <img src={IlustracaoPrecificacao} alt="Caixa de mensagem sistema Inovafarma de precificar" className="ilustra" />
                <div className="texto">
                  <img src={IconePedidoCompra} alt="Icone compras" className="icone" />
                  <TituloPrimario>Precificação</TituloPrimario>
                  <p>Pratique um preço competitivo, distribua os descontos de forma estratégica e ainda sim garanta sua margem de lucro.</p>
                </div>
              </div>
              <div className="item">
                <img src={IlustracaoCurvaABC} alt="Caixa de mensagem sistema Inovafarma de giro de estoque" className="ilustra" />
                <div className="texto">
                  <img src={IconePedidoCompra} alt="Icone compras" className="icone" />
                  <TituloPrimario>Curva ABC</TituloPrimario>
                  <p>Classifique e acompanhe os produtos que impactam diretamente no volume de faturamento da farmácia.</p>
                </div>
              </div>
              <div className="item">
                <img src={IlustracaoGestaoCategoria} alt="Graficos sistema Inovafarma" className="ilustra" />
                <div className="texto">
                  <img src={IconeGestaoCategoria} alt="Icone atualizar" className="icone" />
                  <TituloPrimario>Gestão de categorias</TituloPrimario>
                  <p>Entenda o comportamento de venda da sua farmácia e descubra quais são as categorias mais lucrativas.</p>
                </div>
              </div>
            </div>
          </Container>
        </SectionIntegracoes>

        <SectionVideo>
          <Container>
            <TituloPrimario>Veja como é simples fazer a gestão de compra e estoque da sua farmácia</TituloPrimario>
            <Video className="video video-controle-estoque" url="https://www.youtube.com/watch?v=Oqklj1u8Ndw" light playing />
          </Container>
        </SectionVideo>

        <Demo />

        <Footer padding_maior={true} />
      </div>
    )
  }
}

export default PageControleEstoque
